import './App.css';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import PDFController from './PDFController';

import mainBanner from './images/main-banner.JPG';
import card_1_1 from './images/card-1-1.JPG';
import card_1_2 from './images/card-1-2.JPG';
import card_1_3 from './images/card-1-3.JPG';

import card_2_1 from './images/card-2-1.JPG';
import card_2_2 from './images/card-2-2.JPG';
import card_2_3 from './images/card-2-3.JPG';

import card_3_1 from './images/card-3-1.JPG';
import card_3_2 from './images/card-3-2.JPG';
import card_3_3 from './images/card-3-3.JPG';

import card_4_1 from './images/card-4-1.JPG';
import card_4_2 from './images/card-4-2.JPG';
import card_4_3 from './images/card-4-3.JPG';

import card_5_1 from './images/card-5-1.JPG';
import card_5_2 from './images/card-5-2.JPG';
import card_5_3 from './images/card-5-3.JPG';

import card_6_1 from './images/card-6-1.JPG';
import card_6_2 from './images/card-6-2.JPG';
import card_6_3 from './images/card-6-3.JPG';

import card_7_1 from './images/card-7-1.JPG';
import card_7_2 from './images/card-7-2.JPG';
import card_7_3 from './images/card-7-3.JPG';
import card_7_4 from './images/card-7-4.JPG';
import card_7_5 from './images/card-7-5.JPG';

import card_8_1 from './images/card-8-1.JPG';
import card_8_2 from './images/card-8-2.JPG';
import card_8_3 from './images/card-8-3.JPG';

import card_9_1 from './images/card-9-1.JPG';
import card_9_2 from './images/card-9-2.JPG';
import card_9_3 from './images/card-9-3.JPG';

import family_option from './pdfs/family_option.pdf';
import general_plan from './pdfs/general_plan.pdf';
import room_option from './pdfs/room_option.pdf';
import suite_option from './pdfs/suite_option.pdf';

function App() {
  return (
    <div className="App">
      <Grid container spacing={2} style={{padding: '10px 0px 10px 10px', height: 95}}>
        <Grid className='offer-text' item xs={12}>
          <span>Apartments (bedroom with attached washroom + fully equipped kitchen) avilable now for 600USD per month + 50% of electricity bill till end of November</span>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid className="center" item xs={5}>
          <h1 className="main-title" style={{ marginLeft: 40, marginTop: 10, marginBottom: 10 }}>
            Villa Polhena
          </h1>
        </Grid>
        <Grid className="center" item xs={4}>
          <h3 style={{ marginLeft: 40 }}>
            <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: 10 }} />
            Call now :
            <a style={{ textDecoration: 'none', color: "#707070" }} href="tel:+94779207335">+94779207335</a>,
            <a style={{ textDecoration: 'none', color: "#707070" }} href="tel:+94768460029">+94768460029</a>
          </h3>
        </Grid>
        <Grid item xs={3}>
          <h3 style={{ marginLeft: 40 }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />
            Email :
            <a style={{ textDecoration: 'none', color: "#707070" }} href="mailto:villapolhena@gmail.com">villapolhena@gmail.com</a>
          </h3>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img style={{ width: "100%" }} src={mainBanner} />
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <h2 className="sub-heading" style={{ marginTop: 60, marginBottom: 60 }}>"Your authentic experience starts here...</h2>
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_1_1} />
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>"The Tall Trees..."</div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_1_2} />
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>"The Wooden Gate..."</div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_1_3} />
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>"The Bamboo Shade..."</div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <h2 className="sub-heading" style={{ marginTop: 60, marginBottom: 60 }}>...and goes on."</h2>
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_2_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "101%" }} src={card_2_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_2_3} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_3_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_3_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_3_3} />
          </div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Interior</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 60, paddingLeft: 20 }}>Furnished with traditions in mind with a modern twist...</h2>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_4_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_4_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_4_3} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_5_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_5_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_5_3} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_6_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_6_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_6_3} />
          </div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Sanitary</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 60, paddingLeft: 20 }}>A cold bath or a warm bath...</h2>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_7_1} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_7_4} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_7_3} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_7_5} />
          </div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Dining</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 60, paddingLeft: 20 }}>"Dining is and always was a great artistic opportunity" - Frank Lloyd Wright</h2>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_8_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_8_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_8_3} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_9_1} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_9_2} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="img-hover-zoom">
            <img style={{ width: "100%" }} src={card_9_3} />
          </div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Bookings</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 20, paddingLeft: 20 }}>
              Please call <a style={{ textDecoration: 'none', color: "#707070" }} href="tel:+94779207335">+94779207335</a>, <a style={{ textDecoration: 'none', color: "#707070" }} href="tel:+94768460029">+94768460029</a> or email us on <a style={{ textDecoration: 'none', color: "#707070" }} href="mailto:villapolhena@gmail.com">villapolhena@gmail.com</a>
            </h2>
          </div>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Convenient location</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 60, paddingLeft: 20 }}>Get down from the Katunayake Bandaranaike International Airport. Come down the Southern Expressway. Just under 10km from the Matara interchange and 350m walking distance away from the Matara Polhena beach.</h2>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3968.4017774907134!2d80.52055301437389!3d5.939230931328032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae13f4aa61b12fd%3A0x3d9c1aececac922c!2sVilla%20Polhena!5e0!3m2!1sen!2slk!4v1635275846646!5m2!1sen!2slk" width="900" height="450" style={{ border: 0 }} loading="lazy"></iframe>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="sub-heading" style={{ marginTop: 60, paddingLeft: 20 }}>Floor & Room Plan</h2>
          </div>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 60, paddingLeft: 20 }}>Multiple configurations for your convenience. With flexibility and ease of mind, just let us know what best suits you.</h2>
          </div>
        </Grid>
      </Grid>
      {/* <PDFController wording={"General Plan :"} pdf={general_plan} /> */}
      <PDFController wording={"Suite Options :"} pdf={suite_option} />
      {/* <PDFController wording={"Family Options :"} pdf={family_option} /> */}
      {/* <PDFController wording={"Room Only Options :"} pdf={room_option} /> */}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Document, Page, pdfjs } from "react-pdf";

function PDFController(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <div>
            <h2 className="second-sub-heading" style={{ marginBottom: 20, paddingLeft: 20 }}>{props.wording}</h2>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Document file={props.pdf} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </Grid>
      </Grid>
    </div>
  )
}

export default PDFController;